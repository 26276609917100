<template>
    <auth-page-template heading="Forgot Password" :back-to-login="true">
        <template #subSection>
            <p>Enter the email associated with your account, and we’ll send you a link to reset your password.</p>
        </template>
        <template #default>
            <div class="">
                <validated-input type="email" label="Enter Email ID"></validated-input>
                <btn design="" block class="btn-bg-white mt-4" size="lg" border-radius="4" text="Submit"></btn>
            </div>
        </template>
    </auth-page-template>
</template>

<script>
import AuthPageTemplate from '../../components/auth/AuthPageTemplate';

export default {
    name: 'ForgotPassword',
    components: { AuthPageTemplate }
};
</script>

<style scoped>

</style>
